import React, { useState } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import { Icon, OverlayTrigger, Tooltip } from '@edx/paragon';
import { Check, Remove } from '@edx/paragon/icons';
import { TextField, Icon as ThemeIcon } from 'frontend-design-system';
import PropTypes from 'prop-types';

import { LETTER_REGEX, NUMBER_REGEX } from '../data/constants';
import messages from './messages';

const PasswordField = (props) => {
  const { formatMessage } = useIntl();
  const [isPasswordHidden, setPasswordHidden] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleBlur = (e) => {
    if (props.handleBlur) { props.handleBlur(e); }
    setShowTooltip(props.showRequirements && false);
  };

  const handleFocus = (e) => {
    if (props.handleFocus) {
      props.handleFocus(e);
    }
    setTimeout(() => setShowTooltip(props.showRequirements && true), 150);
  };

  const handlePasswordHidden = () => {
    setPasswordHidden((prevState) => !prevState);
  };

  const placement = window.innerWidth < 768 ? 'top' : 'left';
  const tooltip = (
    <Tooltip id={`password-requirement-${placement}`}>
      <span id="letter-check" className="d-flex align-items-center">
        {LETTER_REGEX.test(props.value) ? <Icon className="text-success mr-1" src={Check} /> : <Icon className="mr-1 text-light-700" src={Remove} />}
        {formatMessage(messages['one.letter'])}
      </span>
      <span id="number-check" className="d-flex align-items-center">
        {NUMBER_REGEX.test(props.value) ? <Icon className="text-success mr-1" src={Check} /> : <Icon className="mr-1 text-light-700" src={Remove} />}
        {formatMessage(messages['one.number'])}
      </span>
      <span id="characters-check" className="d-flex align-items-center">
        {props.value.length >= 8 ? <Icon className="text-success mr-1" src={Check} /> : <Icon className="mr-1 text-light-700" src={Remove} />}
        {formatMessage(messages['eight.characters'])}
      </span>
    </Tooltip>
  );

  const passwordButton = (
    <button
      type="button"
      onFocus={handleFocus}
      onBlur={handleBlur}
      name="password"
      onClick={handlePasswordHidden}
      className="password-button"
    >
      {isPasswordHidden ? <ThemeIcon name="visibility" /> : <ThemeIcon name="visibility-off" />}
    </button>
  );

  return (
    <OverlayTrigger key="tooltip" placement={placement} overlay={tooltip} show={showTooltip}>
      <div>
        <TextField
          type={isPasswordHidden ? 'password' : 'text'}
          name={props.name}
          value={props.value}
          autoComplete={props.autoComplete}
          error={Boolean(props.errorMessage)}
          errorText={props.errorMessage}
          label={props.floatingLabel}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={props.handleChange}
          InputProps={{
            endAdornment: passwordButton,
          }}
        />
      </div>
    </OverlayTrigger>
  );
};

PasswordField.defaultProps = {
  borderClass: '',
  errorMessage: '',
  handleBlur: null,
  handleFocus: null,
  handleChange: () => {},
  showRequirements: true,
  autoComplete: null,
};

PasswordField.propTypes = {
  borderClass: PropTypes.string,
  errorMessage: PropTypes.string,
  floatingLabel: PropTypes.string.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  showRequirements: PropTypes.bool,
  value: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
};

export default PasswordField;
