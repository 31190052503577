import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import classNames from 'classnames';
import { Typography } from 'frontend-design-system';

import messages from './messages';

const LargeLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="w-50 d-flex auth-container zindex-9">
      <div className="col-md-12 auth-info-wrapper">
        <div className="d-flex align-items-center">
          <div className={classNames({ 'large-yellow-line mr-n4.5': getConfig().SITE_NAME === 'edX' })} />
          <Typography
            className="auth-title"
            as="h1"
            variant="d1"
            fontWeight="bold"
          >
            {formatMessage(messages['auth.title'])}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LargeLayout;
