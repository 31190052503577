import React, { useState } from 'react';

import { TextField } from 'frontend-design-system';
import PropTypes, { string } from 'prop-types';

const UsernameField = (props) => {
  const {
    handleSuggestionClick,
    usernameSuggestions,
    errorText,
  } = props;

  const [isFieldTouched, setFieldTouched] = useState(false);
  const isSuggestionsVisible = (isFieldTouched && props.value === '') || (isFieldTouched && Boolean(errorText));

  const handleFocus = (event) => {
    setFieldTouched(true);
    props.handleFocus(event);
  };

  return (
    <div>
      <TextField
        {...props}
        onFocus={handleFocus}
      />
      {isSuggestionsVisible && usernameSuggestions.length ? (
        <div className="scroll-suggested-username">
          {usernameSuggestions.map((username, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div className="username-suggestion-wrapper" key={`suggestion-${index.toString()}`} onClick={(e) => handleSuggestionClick(e, 'username', username)}>
              <div className="username-suggestion data-hj-suppress">
                {username}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

UsernameField.defaultProps = {
  usernameSuggestions: [],
  errorText: '',
  autoComplete: null,
};

UsernameField.propTypes = {
  usernameSuggestions: PropTypes.arrayOf(string),
  handleSuggestionClick: PropTypes.func.isRequired,
  handleUsernameSuggestionClose: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  handleFocus: PropTypes.func.isRequired,
};

export default UsernameField;
